<template>
  <Page>
    <grid
      type="lead_rotation"
      title="Custom lead forms"
      :isMain="true"
      :visible="['lead_rotation_name']"
      style="height: 100%"
      :onCreate="onCreate"
    />
  </Page>
</template>

<script>
import Grid from '@/components/ui/Grid/Grid.vue'

import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  components: {
    Grid
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    onCreate(type, filters) {
      return this.$store.dispatch('LeadRotation/goToCreatePage', {
        type,
        filters
      })
    }
  }
}
</script>
